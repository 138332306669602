<template>
  <div class="about">
    <article class="row">
      <div class="column">
        <h1 class="headline">I create compelling interfaces
          <br>in design and code.
        </h1>
        <h2
          class="subline"
        >Being dual-disciplined I am able to deliver user-centered, aesthetically pleasing and high performing interfaces.</h2>
      </div>
      <div class="column column-50">
        <p
          class="top-spacing"
        >Many job titles might be able to describe my skillset, yet I simply see myself as a reliable partner when designing and building interfaces for the web.</p>
        <p>I utilize modern design and development methodologies to ensure a well thought out user experience and reliable, maintainable code.</p>
        <p>When I'm not building web interfaces I usually spend my time in game development or with on- and offline design and development communities.</p>
      </div>
    </article>
    <article class="row">
      <div class="column">
        <h4>Work experience</h4>
        <ul>
          <li>
            <strong>LOFT48° &mdash; Jan 2017 - today</strong>
            <br>
            <span>Web Designer &amp; Developer</span>
            <br>
            <small>Front-End Development, JavaScript, jQuery, Contao, Wordpress, UI Design, User Flows, User Research, SEO, SEM</small>
          </li>
          <li>
            <strong>Freelance &mdash; May 2014 - Jan 2017</strong>
            <br>
            <span>Web Designer &amp; Developer</span>
            <br>
            <small>Front-End Development, JavaScript, jQuery, Contao, UI Design, SEO</small>
          </li>
          <li>
            <strong>artif &mdash; Sep 2013 - Apr 2014</strong>
            <br>
            <span>Web Designer</span>
            <br>
            <small>Front-End Development, SEO, SEM</small>
          </li>
          <li>
            <strong>triple-sixty &mdash; Oct 2010 - Jul 2013</strong>
            <br>
            <span>Apprentice Digital Media Designer (practical training)</span>
            <br>
            <small>Front-End Development, Contao CMS</small>
          </li>
        </ul>
      </div>
      <div class="column">
        <h4>Education</h4>

        <ul>
          <li>
            <strong>Kerschensteinerschule Reutlingen &mdash; Sep 2015 - Jul 2016</strong>
            <br>
            <span>Fachhochschulreife (vocational college degree)</span>
            <br>
            <small>General education, design education, design principles</small>
          </li>
          <li>
            <strong>Kerschensteinerschule Reutlingen &mdash; Sep 2009 - July 2013</strong>
            <br>
            <span>Apprentice Digital Media Designer (theoretical training)</span>
            <br>
            <small>General design education, Photoshop, InDesign, Illustrator, Flash</small>
          </li>
        </ul>
      </div>
    </article>
  </div>
</template>

<style scoped>
.top-spacing {
  margin-top: 9rem;
}

@media screen and (max-width: 40rem) {
  .top-spacing {
    margin-top: 2rem;
  }
}
</style>


<script>
export default {
  components: {}
};
</script>

